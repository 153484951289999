export function dragToScroll(ele: HTMLElement) {
    let state: {
        left: number,
        top: number,
        x: number,
        y: number,
        moved: boolean,
    } | undefined;

    let scrollable: boolean;

    function updateScrollable(force?: boolean) {
        const newScrollable =
            ele.scrollWidth > ele.offsetWidth || ele.scrollHeight > ele.offsetHeight;
        if(newScrollable === scrollable && !force)
            return;
        scrollable = newScrollable;
        if(scrollable) {
            if(state)
                ele.style.cursor = 'grabbing';
            else
                ele.style.cursor = 'grab';
        } else {
            ele.style.removeProperty('cursor');
        }
    }

    function preventDefault(e: Event) {
        e.preventDefault();
    }

    function clientX(e: MouseEvent | TouchEvent) {
        if('touches' in e)
            return e.touches[0]?.clientX;
        else
            return (e as MouseEvent).clientX;
    }

    function clientY(e: MouseEvent | TouchEvent) {
        if('touches' in e)
            return e.touches[0]?.clientY;
        else
            return (e as MouseEvent).clientY;
    }

    function onStart(e: MouseEvent | TouchEvent) {
        state = {
            left: ele.scrollLeft,
            top: ele.scrollTop,
            x: clientX(e),
            y: clientY(e),
            moved: false,
        };
        updateScrollable(true);
        ele.style.userSelect = 'none';
    }

    function onMove(e: MouseEvent | TouchEvent) {
        updateScrollable();
        if(!state) return;
        state.moved = true;
        ele.scrollLeft = state.left - clientX(e) + state.x;
        ele.scrollTop = state.top - clientY(e) + state.y;
    }

    function onEnd(e: MouseEvent | TouchEvent) {
        if(!state) return;
        if(state.moved) {
            ele.addEventListener('click', preventDefault, {once: true});
            setTimeout(() => ele.removeEventListener('click', preventDefault));
        }
        state = undefined;
        updateScrollable(true);
        ele.style.removeProperty('user-select');
    }

    ele.addEventListener('mousedown', onStart);
    ele.addEventListener('touchstart', onStart);
    ele.addEventListener('mousemove', onMove);
    ele.addEventListener('touchmove', onMove);
    document.addEventListener('mouseup', onEnd);
    document.addEventListener('touchend', onEnd);

    ele.addEventListener('dragstart', preventDefault);

    return {
        destroy() {
            document.removeEventListener('mouseup', onEnd);
            document.removeEventListener('touchend', onEnd);
        }
    };
}
