import type {Tags} from '../lib/osm';
import type {POI, Link, POIWithKind} from '../poi';
import {linksOfCommon, imagesOfCommon, includesTag, pickTags} from '.';

enum Kind {
    HOSPITAL,
    PHARMACY,
    DOCTOR,
    PHYSICAL,
    PSYCHOLOGY,
    DENTIST,
    OTHER,
}

const STYLES: {[key in Kind]: {[key: string]: string}} = {
    [Kind.HOSPITAL]: {color: '#0000ff'},
    [Kind.PHARMACY]: {color: '#ff0000'},
    [Kind.DOCTOR]: {color: '#00c000'},
    [Kind.PHYSICAL]: {color: '#ffa500'},
    [Kind.PSYCHOLOGY]: {color: '#ff80ff'},
    [Kind.DENTIST]: {color: '#ffff00'},
    [Kind.OTHER]: {color: '#ff8080'},
};

function styleFor(poi: POIWithKind): {[key: string]: string} {
    let style = STYLES[poi.kind as Kind];
    if(poi.tags.healthcare == 'alternative')
        style = {...style, color: `${style.color}40`};
    return style;
}

function specialty(tags: Tags, ...specialties: Array<string>) {
    const speciality = tags['healthcare:speciality']?.split(';').map(s => s.trim());
    for(const key of specialties) {
        if(speciality?.includes(key))
            return true;
        const value = tags[`health_specialty:${key}`];
        if(value && value != 'no')
            return true;
    }
    return false;
}

function match(tags: Tags): Kind | undefined {
    if(includesTag(tags.amenity, 'hospital', 'clinic') ||
        includesTag(tags.healthcare, 'hospital'))
        return Kind.HOSPITAL;
    if(includesTag(tags.amenity, 'pharmacy') || includesTag(tags.shop, 'medical_supply'))
        return Kind.PHARMACY;
    if(includesTag(tags.healthcare, 'sample_collection'))
        return Kind.PHARMACY;
    if(includesTag(tags.shop, 'massage') ||
        includesTag(tags.healthcare, 'physiotherapist', 'podologist'))
        return Kind.PHYSICAL;
    if(includesTag(tags.healthcare, 'psychotherapist') ||
        includesTag(tags.office, 'therapist') ||
        specialty(tags, 'hypnosis'))
        return Kind.PSYCHOLOGY;
    if(includesTag(tags.amenity, 'dentist') || includesTag(tags.healthcare, 'dentist'))
        return Kind.DENTIST;
    if(tags.healthcare || includesTag(tags.amenity, 'doctor', 'doctors'))
        return Kind.DOCTOR;
    if(tags['healthcare:speciality'])
        return Kind.OTHER;
}

function linksOf(poi: POI): Array<Link> {
    const tags = poi.tags;
    const links: Array<Link> = [
        ...linksOfCommon(poi),
    ];
    if(tags.email || tags['contact:email'])
        links.push({
            title: "E-Mail",
            href: `mailto:${tags.email ?? tags['contact:email']}`,
        });
    if(tags.phone || tags['contact:phone'])
        links.push({
            title: "Telefon",
            href: `tel:${tags.phone ?? tags['contact:phone']}`,
        });
    return links;
}

function tagsOf(poi: POI): Tags {
    const result: Tags = {};
    pickTags(result, poi, 'amenity', 'shop', 'office', 'level');
    pickTags(result, poi, 'healthcare', 'healthcare:',
        'health_specialty:', 'health_service:',
        'health_person:', 'health_facility:', 'health_amenity:');
    pickTags(result, poi, 'medical_system:', 'counselling_type:', 'disease:', 'provided_for:');
    pickTags(result, poi, 'dispensing', 'drive_through', 'emergency', 'massage');
    pickTags(result, poi, 'opening_hours', 'operator', 'operator:', 'owner');
    pickTags(result, poi, 'wheelchair', 'wheelchair:', ':wheelchair');
    pickTags(result, poi, 'note', 'fixme');
    return result;
}

export {Kind, styleFor, match, linksOf, imagesOfCommon as imagesOf, tagsOf};
