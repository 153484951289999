<script lang="ts">import { onDestroy, createEventDispatcher } from 'svelte';
import * as L from 'leaflet';
import 'Leaflet.Deflate';
import { get as getMap } from './Map.svelte';
export let point = undefined;
export let line = undefined;
export let area = undefined;
export let style = undefined;
const MARKER_RADIUS = matchMedia('(pointer: fine)') ? 6 : 10;
let shape;
if (+!!point + +!!line + +!!area != 1) {
    throw "need exactly one of `point`, `line`, or `area`";
}
if (point)
    shape = new L.CircleMarker(point, Object.assign({ radius: MARKER_RADIUS }, style));
if (line)
    shape = new L.Polyline(line, style);
if (area)
    shape = new L.Polygon(area, style);
if (!(shape))
    shape = undefined;
const dispatch = createEventDispatcher();
shape.on('click', () => dispatch('click', shape));
const map = getMap();
let deflate;
if (line || area) {
    deflate = L.deflate({
        minSize: 2 * MARKER_RADIUS,
        markerType: L.circleMarker,
        markerOptions: Object.assign({ radius: MARKER_RADIUS }, style)
    });
    deflate.addTo(map);
}
shape.addTo(deflate !== null && deflate !== void 0 ? deflate : map);
onDestroy(() => {
    if (shape)
        shape.remove();
    if (deflate)
        deflate.remove();
});
</script>

<script lang="ts" context="module"></script>
