import type {Tags} from '../lib/osm';
import type {POI, POIWithKind} from '../poi';
import {linksOfCommon, imagesOfCommon, includesTag, hasTag, pickTags} from '.';

enum Kind {
    WATER,
}

const STYLES: {[key in Kind]: {[key: string]: string}} = {
    [Kind.WATER]: {color: '#0000ff'},
};

function styleFor(poi: POIWithKind): {[key: string]: string} {
    let style = STYLES[poi.kind as Kind];
    if(poi.tags.access && poi.tags.access != 'yes')
        style = {...style, color: `${style.color}40`};
    return style;
}

function match(tags: Tags): Kind | undefined {
    if(includesTag(tags.amenity, 'drinking_water') ||
       hasTag(tags.drinking_water) || hasTag(tags['drinking_water:legal']) ||
       includesTag(tags.fountain, 'drinking'))
        return Kind.WATER;
}

function tagsOf(poi: POI): Tags {
    const tags = poi.tags;
    const result: Tags = {};

    pickTags(result, poi, 'amenity', 'man_made', 'natural');
    if(includesTag(tags.highway, 'rest_area'))
        result.highway = tags.highway;
    pickTags(result, poi, 'description', 'operator');
    if(hasTag(tags.informal))
        result.informal = tags.informal;
    pickTags(result, poi,
        'fountain', 'drinking_water', 'drinking_water:legal', 'water_characteristic',
        'cold_water', 'warm_water', 'hot_water', 'bottle',
        'indoor', 'seasonal', 'opening_hours', 'availability');
    if(tags.access && tags.access != 'yes')
        result.access = tags.access;
    pickTags(result, poi, 'note', 'fixme');
    return result;
}

export {Kind, styleFor, match, linksOfCommon as linksOf, imagesOfCommon as imagesOf, tagsOf};
