import type {Tags} from '../lib/osm';
import type {POI, POIWithKind} from '../poi';
import {linksOfCommon, imagesOfCommon, includesTag, pickTags, hasTag} from '.';

enum Kind {
    POSTBOX,
    POSTOFFICE,
    PARCELLOCKER,
}

const STYLES: {[key in Kind]: {[key: string]: string}} = {
    [Kind.POSTBOX]: {color: '#ffa000'},
    [Kind.POSTOFFICE]: {color: '#ff0000'},
    [Kind.PARCELLOCKER]: {color: '#0000ff'},
};

function styleFor(poi: POIWithKind): {[key: string]: string} {
    let style = STYLES[poi.kind as Kind];
    if(poi.tags.access && poi.tags.access != 'yes')
        style = {...style, color: `${style.color}40`};
    return style;
}

function match(tags: Tags): Kind | undefined {
    if(includesTag(tags.amenity, 'post_box'))
        return Kind.POSTBOX;
    if(includesTag(tags.amenity, 'post_office') || hasTag(tags.post_office))
        return Kind.POSTOFFICE;
    if(includesTag(tags.amenity, 'parcel_locker'))
        return Kind.PARCELLOCKER;
}

function tagsOf(poi: POI): Tags {
    const tags = poi.tags;
    const result: Tags = {};

    pickTags(result, poi, 'amenity', 'opening_hours', 'collection_times');
    pickTags(result, poi, 'ref', 'description', 'operator', 'post_office', 'post_office:');
    pickTags(result, poi, 'drive_through', 'brand', 'colour');
    pickTags(result, poi, 'parcel_mail_in', 'parcel_pickup', 'payment', 'surveillance');
    pickTags(result, poi, 'post_box:', 'ref:');
    if(tags.access && tags.access != 'yes')
        result.access = tags.access;
    pickTags(result, poi, 'note', 'fixme');
    return result;
}

export {Kind, styleFor, match, linksOfCommon as linksOf, imagesOfCommon as imagesOf, tagsOf};
