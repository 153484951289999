import type {Tags} from '../lib/osm';
import type {POI, POIWithKind} from '../poi';
import {linksOfCommon, imagesOfCommon, includesTag, hasTag, pickTags} from '.';

enum Kind {
    TOILETS,
    SANITARY,
}

const STYLES: {[key in Kind]: {[key: string]: string}} = {
    [Kind.TOILETS]: {color: '#ff0000'},
    [Kind.SANITARY]: {color: '#0000ff'},
};

function styleFor(poi: POIWithKind): {[key: string]: string} {
    let style = STYLES[poi.kind as Kind];
    if(poi.tags.access && poi.tags.access != 'yes' ||
       poi.tags['toilets:access'] && poi.tags['toilets:access'] != 'yes')
        style = {...style, color: `${style.color}40`};
    return style;
}

function match(tags: Tags): Kind | undefined {
    if(includesTag(tags.amenity, 'toilets') || includesTag(tags.building, 'toilets'))
        return Kind.TOILETS;
    if(hasTag(tags.toilets))
        return Kind.TOILETS;
    if(includesTag(tags.amenity, 'shower', 'public_bath'))
        return Kind.SANITARY;
}

function tagsOf(poi: POI): Tags {
    const tags = poi.tags;
    const result: Tags = {};

    pickTags(result, poi, 'amenity', 'building');
    if(tags.toilets && tags.toilets != 'no')
        result.toilets = tags.toilets;
    pickTags(result, poi, 'toilets:', 'bath:');
    pickTags(result, poi, 'composting', 'changing_table', 'drinking_water', 'hot_water',
        'shower', 'indoor', 'fee', 'supervised', 'central_key',
        'male', 'female', 'unisex', 'gender_segregated', 'child', 'capacity')
    if(tags.informal && tags.informal != 'no')
        result.informal = tags.informal;
    pickTags(result, poi, 'description', 'operator', 'opening_hours');
    if(tags.access && tags.access != 'yes')
        result.access = tags.access;
    pickTags(result, poi, 'wheelchair');
    pickTags(result, poi, 'note', 'fixme');
    return result;
}

export {Kind, styleFor, match, linksOfCommon as linksOf, imagesOfCommon as imagesOf, tagsOf};
