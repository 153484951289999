type Response<Query> = {
    batchcomplete: boolean,
    query: Query,
};

type CategoryMembers = {
    categorymembers: Array<{
        pageid: number,
        ns: number,
        title: string,
    }>,
};

async function commonsImage(
    name: `File:${string}`,
    options: {
        width?: number,
    } = {},
) {
    const {width} = {width: 200, ...options};

    const url = `https://commons.wikimedia.org/wiki/Special:FilePath/${encodeURIComponent(name)}`;

    return {
        url: url,
        thumburl: `${url}?width=${encodeURIComponent(width)}`,
    };
}

async function commonsCategory(
    name: `Category:${string}`,
    options: {
        limit?: number,
        type?: 'file' | 'page' | 'subcat',
    } = {},
) {
    const {limit, type} = {...options};

    let url = `https://commons.wikimedia.org/w/api.php?action=query&format=json&origin=*&list=categorymembers&cmtitle=${encodeURIComponent(name)}`;
    if(limit)
        url += `&cmlimit=${encodeURIComponent(limit)}`;
    if(type)
        url += `&cmtype=${encodeURIComponent(type)}`;

    const response = await fetch(url);
    const result: Response<CategoryMembers> = await response.json();

    return result.query.categorymembers;
}

export {commonsImage, commonsCategory};
