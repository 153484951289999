import type {Tags} from '../lib/osm';
import type {POI, POIWithKind} from '../poi';
import {linksOfCommon, imagesOfCommon, includesTag, pickTags} from '.';

enum Kind {
    CASTANEA,           // Esskastanie
    CORYLUS,            // Haselnuss
    CYDONIA,            // Quitte
    JUGLANS,            // Walnuss
    MALUS,              // Apfel
    PRUNUS_ARMENIACA,   // Aprikose
    PRUNUS_AVIUM,       // Süßkirsche
    PRUNUS_CERASUS,     // Sauerkirsche
    PRUNUS_DOMESTICA,   // Zwetschge, Pflaume
    PRUNUS_PERSICA,     // Pfirsich
    PRUNUS,
    PYRUS,              // Birne
}

const STYLES: {[key in Kind]: {[key: string]: string}} = {
    [Kind.CASTANEA]: {color: '#954535'},
    [Kind.CORYLUS]: {color: '#a96d40'},
    [Kind.CYDONIA]: {color: '#ffe000'},
    [Kind.JUGLANS]: {color: '#31241a'},
    [Kind.MALUS]: {color: '#00c000'},
    [Kind.PRUNUS_ARMENIACA]: {color: '#ffc000'},
    [Kind.PRUNUS_AVIUM]: {color: '#ff0000'},
    [Kind.PRUNUS_CERASUS]: {color: '#e00000'},
    [Kind.PRUNUS_DOMESTICA]: {color: '#8080ff'},
    [Kind.PRUNUS_PERSICA]: {color: '#ffc000'},
    [Kind.PRUNUS]: {color: '#ffa000'},
    [Kind.PYRUS]: {color: '#ffe000'},
};

function styleFor(poi: POIWithKind): {[key: string]: string} {
    let style = STYLES[poi.kind as Kind];
    if(poi.tags.access && poi.tags.access != 'yes')
        style = {...style, color: `${style.color}40`};
    return style;
}

function match(tags: Tags): Kind | undefined {
    if(includesTag(tags.genus, 'Castanea') || includesTag([tags.species, tags.taxon], /^Castanea\b/i))
        return Kind.CASTANEA;
    if(includesTag(tags.genus, 'Corylus') || includesTag([tags.species, tags.taxon], /^Corylus\b/i))
        return Kind.CORYLUS;
    if(includesTag(tags.genus, 'Cydonia') || includesTag([tags.species, tags.taxon], /^Cydonia\b/i))
        return Kind.CYDONIA;
    if(includesTag(tags.genus, 'Juglans') || includesTag([tags.species, tags.taxon], /^Juglans\b/i))
        return Kind.JUGLANS;
    if(includesTag(tags.genus, 'Malus') || includesTag([tags.species, tags.taxon], /^Malus\b/i))
        return Kind.MALUS;
    if(includesTag([tags.species, tags.taxon], /^Prunus\s+armeniaca\b/i))
        return Kind.PRUNUS_ARMENIACA;
    if(includesTag([tags.species, tags.taxon], /^Prunus\s+avium\b/i))
        return Kind.PRUNUS_AVIUM;
    if(includesTag([tags.species, tags.taxon], /^Prunus\s+cerasus\b/i))
        return Kind.PRUNUS_CERASUS;
    if(includesTag([tags.species, tags.taxon], /^Prunus\s+domestica\b/i))
        return Kind.PRUNUS_DOMESTICA;
    if(includesTag([tags.species, tags.taxon], /^Prunus\s+persica\b/i))
        return Kind.PRUNUS_PERSICA;
    if(includesTag(tags.genus, 'Prunus') || includesTag([tags.species, tags.taxon], /^Prunus\b/i))
        return Kind.PRUNUS;
    if(includesTag(tags.genus, 'Pyrus') || includesTag([tags.species, tags.taxon], /^Pyrus\b/i))
        return Kind.PYRUS;
}

function tagsOf(poi: POI): Tags {
    const tags = poi.tags;
    const result: Tags = {};

    pickTags(result, poi, 'natural', 'barrier');
    pickTags(result, poi, 'genus', 'genus:', 'species', 'species:', 'taxon', 'taxon:');
    pickTags(result, poi, 'description', 'operator');
    if(tags.access && tags.access != 'yes')
        result.access = tags.access;
    pickTags(result, poi, 'note', 'fixme');
    return result;
}

export {Kind, styleFor, match, linksOfCommon as linksOf, imagesOfCommon as imagesOf, tagsOf};
