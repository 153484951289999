<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { namesOf, datesOf } from './poi';
import { dragToScroll } from './lib/drag-to-scroll';
import { nl2br } from './lib/html';
export let poi;
export let linksOf;
export let imagesOf;
export let tagsOf;
export let membershipsOf = undefined;
$: id = poi.id;
$: type = 'point' in poi ? 'node' : 'points' in poi ? 'way' : 'relation';
$: names = namesOf(poi);
$: links = linksOf(poi);
let images = [];
$: resolveImages(imagesOf(poi));
$: tags = tagsOf(poi);
$: dates = featureDatesOf(datesOf(poi));
$: memberships = membershipsOf && membershipsOf(poi);
function resolveImages(unresolved) {
    return __awaiter(this, void 0, void 0, function* () {
        images = [];
        const all = yield Promise.all(unresolved.map(image => {
            if (typeof image == 'function')
                return image();
            return Promise.resolve(image);
        }));
        images = (all
            .flat()
            .filter((image, index, self) => {
            if (!image)
                return;
            return self.findIndex(other => other && other.href == image.href) == index;
        }));
    });
}
function featureDatesOf(dates) {
    const result = [];
    if (dates.checked)
        result.push([undefined, dates.checked]);
    if (dates.features)
        result.push(...Object.entries(dates.features));
    return result;
}
function openAsyncLink(link) {
    return (e) => {
        e.preventDefault();
        const target = e.target;
        const promise = link.href();
        target.setAttribute('disabled', '');
        return promise
            .then(url => {
            window.open(url);
        }).catch(exc => {
            console.error(exc);
        }).then(() => {
            target.removeAttribute('disabled');
        });
    };
}
function tagEntries(tags) {
    const result = [];
    let previous;
    for (const key in tags) {
        const value = tags[key];
        const index = key.indexOf(':');
        if (index < 0) {
            const title = key;
            result.push([title, false, value]);
            previous = title;
        }
        else {
            const title = key.substring(0, index);
            const subtitle = key.substring(index + 1);
            if (title !== previous)
                result.push([title, false]);
            result.push([subtitle, true, value]);
            previous = title;
        }
    }
    return result;
}
</script>

<header>
    <a rel="external" href={`https://www.openstreetmap.org/${type}/${id}`} target="_blank">
    {#if type == 'relation' && poi.tags.type}
        <small>{poi.tags.type}</small>
    {:else}
        *
    {/if}
    </a>
</header>

{#if names.main}
    <b>{names.main}</b><br>
{/if}
{#if names.alternative}
    <i>{names.alternative}</i><br>
{/if}
{#if names.old}
    <i>{names.old}</i><br>
{/if}

{#if links.length}
    <ul class="links">
    {#each links as link}
        {@const href    = typeof link.href == 'string' ? link.href : '#'}
        {@const target  = typeof link.href == 'string' ? '_blank' : null}
        {@const onClick = typeof link.href == 'string' ? null : openAsyncLink(link)}
        <li>
            <a {href} {target} on:click={onClick}>
                {#if typeof link.title == 'string'}
                    {link.title}
                {:else}
                    {link.title[0]} <small>{link.title[1]}</small>
                {/if}
            </a>
        </li>
    {/each}
    </ul>
{/if}

<table>
{#each tagEntries(tags) as [key, indent, value]}
    <tr>
        <th>
        {#if indent}
            -
        {/if}
            {key}
        </th>
        <td>
        {#if key == 'note' || key == 'fixme' || key == 'access'}
            <strong>{@html nl2br(value ?? '')}</strong>
        {:else}
            {@html nl2br(value ?? '')}
        {/if}
        </td>
    </tr>
{/each}
</table>

{#if images.length}
<ul class="images" use:dragToScroll>
{#each images as image}
    <li>
        <!-- svelte-ignore a11y-missing-attribute -->
        <a href={image.href} target="_blank"><img src={image.src} /></a>
    </li>
{/each}
</ul>
{/if}

{#if dates.length}
<ul class="dates">
{#each dates as [feature, date] (feature)}
    <li>
    {#if feature}{feature}: {/if}
    {date.toLocaleDateString()}
    </li>
{/each}
</ul>
{/if}

{#if memberships}
{#each memberships as membership (membership.relation.id)}
    <hr />
    <svelte:self
        poi={membership.relation}
        {linksOf} {imagesOf} {tagsOf}
    />
{/each}
{/if}

<style type="scss">hr {
  border: none;
  border-bottom: 1px solid lightgrey;
}

header {
  position: relative;
}
header a[rel=external] {
  position: absolute;
  right: 5px;
  top: -8px;
  text-decoration: none;
}
:global(hr) ~ header a[rel=external] {
  position: relative;
  right: 0;
  top: 0;
  margin-left: 10px;
}

:global(hr) ~ header {
  float: right;
}

ul {
  padding: 0;
  margin: 0;
}
ul li {
  display: inline-block;
  list-style: none;
  vertical-align: top;
}
ul.links {
  overflow: hidden;
  margin-right: -11px;
}
ul.links li {
  position: relative;
  margin-left: 5px;
  padding-left: 6px;
  left: -11px;
}
ul.links li ::before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  bottom: 2px;
  border-left: 1px solid lightgrey;
}
ul.images {
  white-space: nowrap;
  overflow: hidden;
}
ul.images li {
  line-height: 1;
  margin-right: 3px;
  margin-bottom: 3px;
}
ul.images li img {
  display: block;
}
ul.dates {
  font-size: smaller;
  text-align: right;
  color: darkgrey;
}
ul.dates li + li {
  margin-left: 5px;
  padding-left: 6px;
  border-left: 1px solid lightgrey;
}

table {
  margin: 5px 0;
  border-collapse: collapse;
}

tr > * {
  padding: 1px 2px;
  vertical-align: top;
  text-align: left;
  font-family: monospace;
  font-weight: normal;
}
tr > :first-child {
  padding-left: 0;
  white-space: nowrap;
}
tr > :last-child {
  padding-right: 0;
}

:global(a[disabled]) {
  color: grey;
  pointer-events: none;
}

strong {
  font-weight: normal;
  color: red;
}

img {
  max-width: 200px;
  max-height: 100px;
}</style>
