export type Values = {[key: string]: string};

function decode(query: string): Values {
    if(query[0] == '?' || query[0] == '#')
        query = query.substr(1);
    const result: Values = {};
    query.split('&').forEach(part => {
        const eq = part.indexOf('=');
        if(eq < 0)
            return;
        const k = decodeURIComponent(part.substr(0, eq)),
              v = decodeURIComponent(part.substr(eq+1));
        result[k] = v;
    });
    return result;
}

function encode(values: Values): string {
    let result = '';
    for(const k in values) {
        if(result.length)
            result += '&';
        result += encodeURIComponent(k);
        result += '=';
        result += encodeURIComponent(values[k]);
    }
    return result.replace(/%2F/g, '/');
}

export {decode, encode};
