<script lang="ts">
    import type {Tags} from './lib/osm';
    import type {POI, Membership, AsyncLinkHandler, Link, Image, ImageData, Dates} from './poi';
    import {namesOf, datesOf} from './poi';
    import {dragToScroll} from './lib/drag-to-scroll';
    import {nl2br} from './lib/html';

    export let poi: POI;
    export let linksOf: (poi: POI) => Array<Link>;
    export let imagesOf: (poi: POI) => Array<Image>;
    export let tagsOf: (poi: POI) => Tags;
    export let membershipsOf: ((poi: POI) => Array<Membership>) | undefined = undefined;

    $: id = poi.id;
    $: type = 'point' in poi ? 'node' : 'points' in poi ? 'way' : 'relation';
    $: names = namesOf(poi);
    $: links = linksOf(poi);
    let images: Array<ImageData> = [];
    $: resolveImages(imagesOf(poi));
    $: tags = tagsOf(poi);
    $: dates = featureDatesOf(datesOf(poi));
    $: memberships = membershipsOf && membershipsOf(poi);

    async function resolveImages(unresolved: Array<Image>) {
        images = [];
        const all = await Promise.all(unresolved.map(
            image => {
                if(typeof image == 'function')
                    return image();
                return Promise.resolve(image);
            }
        ));
        images = (all
            .flat()
            .filter((image, index, self) => {
                if(!image)
                    return;
                return self.findIndex(other => other && other.href == image.href) == index;
            })
         ) as Array<ImageData>;
    }

    function featureDatesOf(dates: Dates) {
        const result: Array<[string | undefined, Date]> = [];
        if(dates.checked)
            result.push([undefined, dates.checked]);
        if(dates.features)
            result.push(...Object.entries(dates.features));
        return result;
    }

    function openAsyncLink(link: Link) {
        return (e: MouseEvent) => {
            e.preventDefault();
            const target = e.target as HTMLElement;
            const promise = (link.href as AsyncLinkHandler)();
            target.setAttribute('disabled', '');
            return promise
                .then(url => {
                    window.open(url);
                }).catch(exc => {
                    console.error(exc);
                }).then(() => {
                    target.removeAttribute('disabled');
                });
        }
    }

    function tagEntries(tags: Tags) {
        const result: Array<
            | [title: string, indent: false, value?: string]
            | [title: string, indent: true, value: string]
        > = [];
        let previous: string | undefined;
        for(const key in tags) {
            const value = tags[key];
            const index = key.indexOf(':');
            if(index < 0) {
                const title = key;
                result.push([title, false, value]);
                previous = title;
            } else {
                const title = key.substring(0, index);
                const subtitle = key.substring(index+1);
                if(title !== previous)
                    result.push([title, false]);
                result.push([subtitle, true, value]);
                previous = title;
            }
        }
        return result;
    }
</script>

<header>
    <a rel="external" href={`https://www.openstreetmap.org/${type}/${id}`} target="_blank">
    {#if type == 'relation' && poi.tags.type}
        <small>{poi.tags.type}</small>
    {:else}
        *
    {/if}
    </a>
</header>

{#if names.main}
    <b>{names.main}</b><br>
{/if}
{#if names.alternative}
    <i>{names.alternative}</i><br>
{/if}
{#if names.old}
    <i>{names.old}</i><br>
{/if}

{#if links.length}
    <ul class="links">
    {#each links as link}
        {@const href    = typeof link.href == 'string' ? link.href : '#'}
        {@const target  = typeof link.href == 'string' ? '_blank' : null}
        {@const onClick = typeof link.href == 'string' ? null : openAsyncLink(link)}
        <li>
            <a {href} {target} on:click={onClick}>
                {#if typeof link.title == 'string'}
                    {link.title}
                {:else}
                    {link.title[0]} <small>{link.title[1]}</small>
                {/if}
            </a>
        </li>
    {/each}
    </ul>
{/if}

<table>
{#each tagEntries(tags) as [key, indent, value]}
    <tr>
        <th>
        {#if indent}
            -
        {/if}
            {key}
        </th>
        <td>
        {#if key == 'note' || key == 'fixme' || key == 'access'}
            <strong>{@html nl2br(value ?? '')}</strong>
        {:else}
            {@html nl2br(value ?? '')}
        {/if}
        </td>
    </tr>
{/each}
</table>

{#if images.length}
<ul class="images" use:dragToScroll>
{#each images as image}
    <li>
        <!-- svelte-ignore a11y-missing-attribute -->
        <a href={image.href} target="_blank"><img src={image.src} /></a>
    </li>
{/each}
</ul>
{/if}

{#if dates.length}
<ul class="dates">
{#each dates as [feature, date] (feature)}
    <li>
    {#if feature}{feature}: {/if}
    {date.toLocaleDateString()}
    </li>
{/each}
</ul>
{/if}

{#if memberships}
{#each memberships as membership (membership.relation.id)}
    <hr />
    <svelte:self
        poi={membership.relation}
        {linksOf} {imagesOf} {tagsOf}
    />
{/each}
{/if}

<style type="scss">
    hr {
        border: none;
        border-bottom: 1px solid lightgrey;
    }

    header {
        position: relative;
        a[rel="external"] {
            position: absolute;
            right: 5px;
            top: -8px;
            text-decoration: none;
            :global(hr) ~ & {
                position: relative;
                right: 0;
                top: 0;
                margin-left: 10px;
            }
        }
    }
    :global(hr) ~ header {
        float: right;
    }

    ul {
        padding: 0;
        margin: 0;
        li {
            display: inline-block;
            list-style: none;
            vertical-align: top;
        }
        &.links {
            overflow: hidden;
            margin-right: -(5px + 6px);
            li {
                position: relative;
                margin-left: 5px;
                padding-left: 6px;
                left: -(5px + 6px);
                ::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 2px;
                    bottom: 2px;
                    border-left: 1px solid lightgrey;
                }
            }
        }
        &.images {
            white-space: nowrap;
            overflow: hidden;
            li {
                line-height: 1;
                margin-right: 3px;
                margin-bottom: 3px;
                img {
                    display: block;
                }
            }
        }
        &.dates {
            font-size: smaller;
            text-align: right;
            color: darkgrey;
            li + li {
                margin-left: 5px;
                padding-left: 6px;
                border-left: 1px solid lightgrey;
            }
        }
    }

    table {
        margin: 5px 0;
        border-collapse: collapse;
    }
    tr {
        > * {
            padding: 1px 2px;
            vertical-align: top;
            text-align: left;
            font-family: monospace;
            font-weight: normal;
        }
        > :first-child {
            padding-left: 0;
            white-space: nowrap;
        }
        > :last-child {
            padding-right: 0;
        }
    }

    :global(a[disabled]) {
        color: grey;
        pointer-events: none;
    }

    strong {
        font-weight: normal;
        color: red;
    }

    img {
        max-width: 200px;
        max-height: 100px;
    }
</style>
