import * as L from 'leaflet';
import {PointAttributes, WayAttributes, RelationAttributes, MetaAttributes, Tags, getRelationMembers} from './lib/osm';
import {getTags, getNodeRefs} from './lib/osm';

type Kind<T> = T;

interface POIBase {
    id: string;
    tags: Tags;
    relations?: Array<Membership>;
}

interface POIBaseWithKind extends POIBase {
    kind: Kind<unknown>;
}

interface NodePOI extends POIBaseWithKind {
    point: L.LatLngExpression;
}

interface WayPOI extends POIBaseWithKind {
    points: Array<L.LatLngExpression>;
    area: boolean;
}

interface RelationPOI extends POIBase {
}

interface Membership {
    role: string;
    relation: RelationPOI;
}

type POIWithKind = NodePOI | WayPOI;
type POI = NodePOI | WayPOI | RelationPOI;

class POIs {
    public readonly nodes: Array<NodePOI>;
    public readonly ways: Array<WayPOI>;
    public readonly date: Date;

    constructor(nodes: Array<NodePOI>, ways: Array<WayPOI>, date: Date) {
        this.nodes = nodes;
        this.ways = ways;
        this.date = date;
    }

    bbox() {
        const points: Array<L.LatLngExpression> = [
            ...this.nodes.map(poi => poi.point),
            ...this.ways.flatMap(poi => poi.points),
        ];
        return L.latLngBounds(points);
    }
}

interface Names {
    main?: string;
    alternative?: string;
    old?: string;
}

function namesOf(poi: POI): Names {
    const tags = poi.tags;
    const names: Names = {};
    if(tags.name)
        names.main = tags.name;
    if(tags.alt_name)
        names.alternative = tags.alt_name;
    if(tags.old_name)
        names.old = tags.old_name;
    return names;
}

interface Dates {
    checked?: Date;
    features?: {[key: string]: Date};
}

const CHECK_DATE_PREFIX = 'check_date:';

function datesOf(poi: POI): Dates {
    const tags = poi.tags;
    const dates: Dates = {
        features: {},
    };
    if(tags.check_date)
        dates.checked = new Date(tags.check_date);
    for(const key in tags) {
        if(!key.startsWith(CHECK_DATE_PREFIX))
            continue;
        const value = tags[key];
        if(!value)
            continue;
        const feature = key.substr(CHECK_DATE_PREFIX.length);
        if(!dates.features)
            dates.features = {};
        dates.features[feature] = new Date(value);
    }
    return dates;
}

function isArea(tags: Tags, refs: string[]): boolean {
    if(tags.area == 'no')
        return false;
    return refs[0] == refs[refs.length-1];
}

type AsyncLinkHandler = () => Promise<string>;

type Link = {
    title: string | [main: string, sub: string];
    href: string | AsyncLinkHandler;
};

type ImageData = {
    src: string,
    href: string,
};
type AsyncImageHandler = () => Promise<ImageData | Array<ImageData> | undefined>;

type Image = ImageData | AsyncImageHandler;

async function download(url: string): Promise<Document> {
    const res = await fetch(url);
    const raw = await res.text();
    return new DOMParser().parseFromString(raw, 'text/xml');
}

async function load(url: string, match: (tags: Tags) => Kind<unknown> | undefined): Promise<POIs> {
    const xml = (await download(url)).documentElement;
    const ref2point: {[key: string]: L.LatLngExpression} = {};
    const ref2node: {[key: string]: NodePOI} = {};
    const ref2way: {[key: string]: WayPOI} = {};
    const nodes: Array<NodePOI> = [], ways: Array<WayPOI> = [];
    xml.querySelectorAll(':scope > node').forEach(node => {
        const attrs = node.attributes as PointAttributes;
        const id = attrs.id.value,
              lat = parseFloat(attrs.lat.value),
              lng = parseFloat(attrs.lon.value);
        const point = {lat, lng};
        ref2point[id] = point;
        const tags = getTags(node);
        const kind = match(tags);
        if(kind != undefined) {
            const poi = {id, kind, tags, point};
            ref2node[id] = poi;
            nodes.push(poi);
        }
    });
    xml.querySelectorAll(':scope > way').forEach(way => {
        const tags = getTags(way);
        const kind = match(tags);
        if(kind == undefined)
            return;
        const attrs = way.attributes as WayAttributes;
        const id = attrs.id.value;
        const refs = getNodeRefs(way);
        const points = refs.map(ref => ref2point[ref]);
        const area = isArea(tags, refs);
        const poi = {id, kind, tags, points, area};
        ref2way[id] = poi;
        ways.push(poi);
    });
    xml.querySelectorAll(':scope > relation').forEach(relation => {
        const tags = getTags(relation);
        const attrs = relation.attributes as RelationAttributes;
        const id = attrs.id.value;
        const members = getRelationMembers(relation);
        if(!members.length)
            return;
        const poi = {id, tags};
        members.forEach(member => {
            const memberPoi =
                member.type == 'node' ? ref2node[member.ref] :
                member.type == 'way'  ? ref2way [member.ref] :
                undefined;
            if(!memberPoi)
                return;
            const memberships = (memberPoi.relations = memberPoi.relations || []);
            memberships.push({role: member.role, relation: poi});
        });
    });
    const date = new Date(
        (xml.querySelector(':scope > meta')!.attributes as MetaAttributes)
            .osm_base.value
    );
    return new POIs(nodes, ways, date);
}

export type {Kind, NodePOI, WayPOI, RelationPOI, POI, POIWithKind, Membership, Names, Dates, AsyncLinkHandler, Link, Image, ImageData};
export {POIs, load, namesOf, datesOf};
