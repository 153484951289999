import type {POI, Link, Image} from '../poi';
import type {Tags} from '../lib/osm';
import {commonsCategory, commonsImage} from '../lib/mediawiki';
import {escape as escapeRegExp} from '../lib/regexp';

export function linksOfCommon(poi: POI): Array<Link> {
    const tags = poi.tags;
    const links: Array<Link> = [];
    if(tags.website || tags['contact:website'])
        links.push({
            title: "Webseite",
            href: tags.website ?? tags['contact:website'],
        });
    if(tags.wikipedia) {
        const parts = tags.wikipedia.split(':');
        if(parts.length > 1)
            links.push({
                title: "Wikipedia",
                href: `https://${parts[0]}.wikipedia.org/wiki/${parts.slice(1).join(':')}`,
            });
    }
    if(tags.wikidata)
        links.push({
            title: "Wikidata",
            href: `https://www.wikidata.org/wiki/${tags.wikidata}`,
        });
    if(tags.wikimedia_commons)
        links.push({
            title: "Wikimedia Commons",
            href: `https://commons.wikimedia.org/wiki/${tags.wikimedia_commons}`,
        });
    if(tags['subject:wikipedia']) {
        const parts = tags['subject:wikipedia'].split(':');
        if(parts.length > 1)
            links.push({
                title: ["Wikipedia", "Gegenstand"],
                href: `https://${parts[0]}.wikipedia.org/wiki/${parts.slice(1).join(':')}`,
            });
    }
    if(tags['subject:wikidata'])
        links.push({
            title: ["Wikidata", "Gegenstand"],
            href: `https://www.wikidata.org/wiki/${tags['subject:wikidata']}`,
        });
    if(tags['artist:wikipedia']) {
        const parts = tags['artist:wikipedia'].split(':');
        if(parts.length > 1)
            links.push({
                title: ["Wikipedia", "Künstler"],
                href: `https://${parts[0]}.wikipedia.org/wiki/${parts.slice(1).join(':')}`,
            });
    }
    if(tags['artist:wikidata'])
        links.push({
            title: ["Wikidata", "Künstler"],
            href: `https://www.wikidata.org/wiki/${tags['artist:wikidata']}`,
        });
    if(tags['architect:wikipedia']) {
        const parts = tags['architect:wikipedia'].split(':');
        if(parts.length > 1)
            links.push({
                title: ["Wikipedia", "Architekt"],
                href: `https://${parts[0]}.wikipedia.org/wiki/${parts.slice(1).join(':')}`,
            });
    }
    if(tags['architect:wikidata'])
        links.push({
            title: ["Wikidata", "Architekt"],
            href: `https://www.wikidata.org/wiki/${tags['architect:wikidata']}`,
        });
    if(tags['operator:wikipedia']) {
        const parts = tags['operator:wikipedia'].split(':');
        if(parts.length > 1)
            links.push({
                title: ["Wikipedia", "Betreiber"],
                href: `https://${parts[0]}.wikipedia.org/wiki/${parts.slice(1).join(':')}`,
            });
    }
    if(tags['operator:wikidata'])
        links.push({
            title: ["Wikidata", "Betreiber"],
            href: `https://www.wikidata.org/wiki/${tags['operator:wikidata']}`,
        });
    if(tags['brand:wikipedia']) {
        const parts = tags['brand:wikipedia'].split(':');
        if(parts.length > 1)
            links.push({
                title: ["Wikipedia", "Marke"],
                href: `https://${parts[0]}.wikipedia.org/wiki/${parts.slice(1).join(':')}`,
            });
    }
    if(tags['brand:wikidata'])
        links.push({
            title: ["Wikidata", "Marke"],
            href: `https://www.wikidata.org/wiki/${tags['brand:wikidata']}`,
        });
    return links;
}

export function imagesOfCommon(poi: POI): Array<Image> {
    const tags = poi.tags;
    const images: Array<Image> = [];
    const wikimedia_commons = tags.wikimedia_commons;
    if(wikimedia_commons) {
        if(wikimedia_commons.startsWith('File:'))
            images.push(async () => {
                const image = await commonsImage(wikimedia_commons);
                return {
                    src: image.thumburl,
                    href: image.url,
                };
            });
        if(wikimedia_commons.startsWith('Category:'))
            images.push(async () => {
                const images = await commonsCategory(wikimedia_commons, {limit: 6, type: 'file'});
                const resolved = await Promise.all(
                    images.map(image => commonsImage(image.title as `File:${string}`))
                );
                return resolved.map(image => ({
                    src: image.thumburl,
                    href: image.url,
                }));
            });
    }
    return images;
}

export function includesTag(value: string | undefined | Array<string | undefined>, ...values: Array<string | RegExp>) {
    if(value instanceof Array) {
        for(const v of value) {
            if(includesTag(v, ...values))
                return true;
        }
        return false;
    }
    if(!value)
        return false;
    for(const what of values) {
        if(what == value)
            return true;
        if(what instanceof RegExp && value.match(what))
            return true;
    }
    return false;
}

export function hasTag(value: string | undefined) {
    return value && value != 'no' && value != 'separate';
}

function semicolon2newline(value: string) {
    return value.replace(/\s*;\s*/g, "\n");
}

export function pickTags(result: Tags, poi: POI, ...keys: Array<keyof Tags>): void {
    const tags = poi.tags;
    for(let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if(tags[key])
            result[key] = semicolon2newline(tags[key]);
        if(typeof key == 'string') {
            if(key.startsWith(':')) {
                for(const k in tags) {
                    if(k.endsWith(key))
                        result[k] = semicolon2newline(tags[k]);
                }
            }
            if(key.endsWith(':')) {
                for(const k in tags) {
                    if(k.startsWith(key))
                        result[k] = semicolon2newline(tags[k]);
                }
            }
        }
    }
}

export function pickContinuedTags(result: Tags, poi: POI, ...keys: Array<keyof Tags>): void {
    const tags = poi.tags;
    for(let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const value = [];
        if(tags[key])
            value[0] = tags[key];
        const regex = new RegExp(`^${escapeRegExp(String(key))}[:_]?(\\d+|continue[ds]?)$`);
        for(const k in tags) {
            const match = k.match(regex);
            if(match) {
                const index = parseInt(match[1]) || 0;
                value[index] = tags[k];
            }
        }
        if(value.length) {
            result[key] = value
                .filter(s => s)
                .map(semicolon2newline)
                .join("\n");
        }
    }
}
