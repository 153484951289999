<script lang="ts">
    import Map from './map/Map.svelte';
    import Shape from './map/Shape.svelte';
    import type {LeafletShape} from './map/Shape.svelte';
    import Popup from './map/Popup.svelte';
    import Details from './Details.svelte';
    import {tick} from 'svelte';
    import type {Values as QueryStringValues} from './lib/query-string';
    import type {POI, POIs, Link, Image, POIWithKind, Membership} from './poi';
    import {load} from './poi';
    import type {Tags} from './lib/osm';
    import * as place_of_worship from './flavours/place_of_worship';
    import * as bench from './flavours/bench';
    import * as waste from './flavours/waste';
    import * as toilets from './flavours/toilets';
    import * as water from './flavours/water';
    import * as post from './flavours/post';
    import * as healthcare from './flavours/healthcare';
    import * as fruits from './flavours/fruits';
    import * as surveillance from './flavours/surveillance';

    type DataSet = {
        name: string,
        title: string,
        styleFor: (poi: POIWithKind) => {[key: string]: string},
        match: (tags: Tags) => unknown,
        linksOf: (poi: POI) => Array<Link>,
        imagesOf: (poi: POI) => Array<Image>,
        tagsOf: (poi: POI) => Tags,
        membershipsOf?: (poi: POI) => Array<Membership>,
    };

    const ALL_DATASETS: Array<DataSet> = [
        {
            name: 'place_of_worship-landkreis',
            title: "Kirchen & Flurdenkmäler (Landkreis Bamberg)",
            ...place_of_worship,
        },
        {
            name: 'place_of_worship-stadt',
            title: "Kirchen & Flurdenkmäler (Stadt Bamberg)",
            ...place_of_worship,
        },
        {
            name: 'place_of_worship-lichtenfels',
            title: "Kirchen & Flurdenkmäler (Landkreis Lichtenfels)",
            ...place_of_worship,
        },
        {
            name: 'bench-landkreis',
            title: "Bänke & Tische (Landkreis Bamberg)",
            ...bench,
        },
        {
            name: 'bench-stadt',
            title: "Bänke & Tische (Stadt Bamberg)",
            ...bench,
        },
        {
            name: 'waste-stadt',
            title: "Abfallwirtschaft (Stadt Bamberg)",
            ...waste,
        },
        {
            name: 'toilets-stadt',
            title: "Toiletten (Stadt Bamberg)",
            ...toilets,
        },
        {
            name: 'water-stadt',
            title: "Trinkwasser (Stadt Bamberg)",
            ...water,
        },
        {
            name: 'post-stadt',
            title: "Post (Stadt Bamberg)",
            ...post,
        },
        {
            name: 'healthcare-stadt',
            title: "Gesundheitssystem (Stadt Bamberg)",
            ...healthcare,
        },
        {
            name: 'healthcare-landkreis',
            title: "Gesundheitssystem (Landkreis Bamberg)",
            ...healthcare,
        },
        {
            name: 'fruits-stadt',
            title: "Obstbäume und -sträucher (Stadt Bamberg)",
            ...fruits,
        },
        {
            name: 'fruits-landkreis',
            title: "Obstbäume und -sträucher (Landkreis Bamberg)",
            ...fruits,
        },
        {
            name: 'surveillance-stadt',
            title: "Überwachung (Stadt Bamberg)",
            ...surveillance,
        },
    ];

    const DATASETS_BY_HOST: {[key: string]: string[]} = {
        'smartcity.heriberga.net': [
            'bench-stadt', 'waste-stadt', 'toilets-stadt',
            'water-stadt', 'post-stadt', 'healthcare-stadt',
            'fruits-stadt',
        ],
    }

    function filterDataSets(dataSets: Array<DataSet>): Array<DataSet> {
        const names = DATASETS_BY_HOST[location.hostname];
        if(names)
            return dataSets.filter(dataSet => names.includes(dataSet.name));
        return dataSets;
    }

    const availableDataSets = filterDataSets(ALL_DATASETS);

    let currentVariant: DataSet | undefined;
    let pois: POIs | undefined;

    async function variantChanged(variant: DataSet | undefined)  {
        pois = undefined;
        if(!variant)
            return;
        await tick();
        hash.dataset = variant.name;
        pois = await load(`/data/${variant.name}.osm`, variant.match);
        hideDetails();
        if(!map.hasHashLocation())
            map.zoomTo(pois.bbox());
    }

    $: variantChanged(currentVariant);

    let currentDetails: {poi: POI, shape: LeafletShape} | undefined;

    function showDetails(poi: POI, context: CustomEvent) {
        const shape = context.detail as LeafletShape;
        currentDetails = {poi, shape};
    }

    function hideDetails() {
        currentDetails = undefined;
    }

    async function hashChanged(hash: QueryStringValues) {
        if(!hash)
            return;
        await tick();
        const name = hash.dataset;
        if(name) {
            if(!currentVariant || currentVariant.name != name) {
                const variant = availableDataSets.find(dataset => dataset.name == name);
                if(variant)
                    currentVariant = variant;
            }
        }
        if(!currentVariant)
            currentVariant = availableDataSets[0];
    }

    let map: Map;
    let hash: QueryStringValues;

    $: hashChanged(hash);
</script>

<Map bind:this={map} bind:hash={hash}>
{#if currentVariant && pois}
    {#each pois.nodes as node (node)}
        <Shape
            point={node.point}
            style={currentVariant.styleFor(node)}
            on:click={(e) => showDetails(node, e)}
        />
    {/each}
    {#each pois.ways as way (way)}
        {#if way.area}
            <Shape
                area={way.points}
                style={currentVariant.styleFor(way)}
                on:click={(e) => showDetails(way, e)}
            />
        {:else}
            <Shape
                line={way.points}
                style={currentVariant.styleFor(way)}
                on:click={(e) => showDetails(way, e)}
            />
        {/if}
    {/each}
    <Popup shape={currentDetails?.shape} on:remove={hideDetails}>
        {#if currentDetails}
            <Details
                poi={currentDetails.poi}
                linksOf={currentVariant.linksOf}
                imagesOf={currentVariant.imagesOf}
                tagsOf={currentVariant.tagsOf}
                membershipsOf={currentVariant.membershipsOf}
            />
        {/if}
    </Popup>
{/if}
</Map>

<aside>
    <select bind:value={currentVariant}>
    {#if !currentVariant}
        <option value={undefined}></option>
    {/if}
    {#each availableDataSets as dataset (dataset.name)}
        <option value={dataset}>{dataset.title}</option>
    {/each}
    </select>
{#if pois}
    <small>{pois.date.toLocaleString()}</small>
{/if}
</aside>

<style lang="scss">
    aside {
        position: absolute;
        right: 0; top: 0;
        padding: 10px 20px;
        background: rgba(255,255,255,0.5);
        z-index: 1000;

        small {
            display: block;
        }
    }
</style>
