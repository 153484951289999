import type {Tags} from '../lib/osm';
import type {POI, POIWithKind} from '../poi';
import {linksOfCommon, imagesOfCommon, includesTag, hasTag, pickTags} from '.';

enum Kind {
    WASTE_BASKET,
    RECYCLING,
    INFRASTRUCTURE,
    DOGSHIT,
}

const STYLES: {[key in Kind]: {[key: string]: string}} = {
    [Kind.WASTE_BASKET]: {color: '#0000ff'},
    [Kind.RECYCLING]: {color: '#00c000'},
    [Kind.INFRASTRUCTURE]: {color: '#ff0000'},
    [Kind.DOGSHIT]: {color: '#c08000'},
};

function styleFor(poi: POIWithKind): {[key: string]: string} {
    let style = STYLES[poi.kind as Kind];
    if(poi.tags.access && poi.tags.access != 'yes')
        style = {...style, color: `${style.color}40`};
    return style;
}

function match(tags: Tags): Kind | undefined {
    if(includesTag(tags.vending, 'excrement_bags'))
        return Kind.DOGSHIT;
    if(includesTag(tags.amenity, 'waste_basket', 'waste_disposal'))
        return Kind.WASTE_BASKET;
    if(includesTag(tags.amenity, 'recycling'))
        return Kind.RECYCLING;
    if(includesTag(tags.amenity, 'sanitary_dump_station', 'waste_transfer_station'))
        return Kind.INFRASTRUCTURE;
    if(includesTag(tags.landuse, 'landfill'))
        return Kind.INFRASTRUCTURE;
    if(includesTag(tags.man_made, 'wastewater_plant', 'waste_incineration', 'waste_incineration_plant'))
        return Kind.INFRASTRUCTURE;
    if(includesTag(tags.power, 'plant', 'generator'))
        return Kind.INFRASTRUCTURE;
    if(hasTag(tags.bin))
        return Kind.WASTE_BASKET;
}

function tagsOf(poi: POI): Tags {
    const tags = poi.tags;
    const result: Tags = {};

    pickTags(result, poi, 'amenity', 'landuse', 'man_made');
    if(tags.highway && tags.highway == 'bus_stop')
        result.highway = tags.highway;
    if(tags.power == 'plant' || tags.power == 'generator')
        result.power = tags.power;
    if(tags.bin && tags.bin != 'no')
        result.bin = tags.bin;
    pickTags(result, poi, 'waste', 'landfill:waste', 'recycling_type');
    pickTags(result, poi, 'recycling:');
    pickTags(result, poi, 'plant:source');
    pickTags(result, poi, 'plant:output:');
    pickTags(result, poi, 'generator:source');
    pickTags(result, poi, 'generator:output:');
    pickTags(result, poi, 'vending', 'water', 'location', 'fee', 'capacity');
    if(tags.informal && tags.informal != 'no')
        result.informal = tags.informal;
    pickTags(result, poi, 'description', 'operator', 'opening_hours', 'indoor');
    if(tags.access && tags.access != 'yes')
        result.access = tags.access;
    pickTags(result, poi, 'note', 'fixme');
    return result;
}

export {Kind, styleFor, match, linksOfCommon as linksOf, imagesOfCommon as imagesOf, tagsOf};
