interface PointAttributes extends NamedNodeMap {
    id: Attr,
    lat: Attr,
    lon: Attr,
}

interface WayAttributes extends NamedNodeMap {
    id: Attr,
}

interface RelationAttributes extends NamedNodeMap {
    id: Attr,
}

interface NdAttributes extends NamedNodeMap {
    ref: Attr,
}

interface MemberAttributes extends NamedNodeMap {
    type: Attr,
    ref: Attr,
    role: Attr,
}

interface TagAttributes extends NamedNodeMap {
    k: Attr,
    v: Attr,
}

interface MetaAttributes extends NamedNodeMap {
    osm_base: Attr,
}

interface Tags {
    [key: string]: string,
}

type MemberType = 'node' | 'way' | 'relation';

interface Member {
    type: MemberType,
    ref: string,
    role: string,
}

function getTags(obj: Element): Tags {
    const tags: Tags = {};
    obj.querySelectorAll(':scope > tag').forEach(tag => {
        const attrs = tag.attributes as TagAttributes;
        tags[attrs.k.value] = attrs.v.value;
    });
    return tags;
}

function getNodeRefs(way: Element): Array<string> {
    return Array.from(way.querySelectorAll(':scope > nd')).map(nd => {
        const attrs = nd.attributes as NdAttributes;
        return attrs.ref.value;
    });
};

function getRelationMembers(relation: Element): Array<Member> {
    return Array.from(relation.querySelectorAll(':scope > member')).map(member => {
        const attrs = member.attributes as MemberAttributes;
        return {
            type: attrs.type.value as MemberType,
            ref: attrs.ref.value,
            role: attrs.role.value,
        };
    });
}

export type {PointAttributes, WayAttributes, RelationAttributes, NdAttributes, MemberAttributes, TagAttributes, MetaAttributes, Tags};
export {getTags, getNodeRefs, getRelationMembers};
