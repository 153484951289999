<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Map from './map/Map.svelte';
import Shape from './map/Shape.svelte';
import Popup from './map/Popup.svelte';
import Details from './Details.svelte';
import { tick } from 'svelte';
import { load } from './poi';
import * as place_of_worship from './flavours/place_of_worship';
import * as bench from './flavours/bench';
import * as waste from './flavours/waste';
import * as toilets from './flavours/toilets';
import * as water from './flavours/water';
import * as post from './flavours/post';
import * as healthcare from './flavours/healthcare';
import * as fruits from './flavours/fruits';
import * as surveillance from './flavours/surveillance';
const ALL_DATASETS = [
    Object.assign({ name: 'place_of_worship-landkreis', title: "Kirchen & Flurdenkmäler (Landkreis Bamberg)" }, place_of_worship),
    Object.assign({ name: 'place_of_worship-stadt', title: "Kirchen & Flurdenkmäler (Stadt Bamberg)" }, place_of_worship),
    Object.assign({ name: 'place_of_worship-lichtenfels', title: "Kirchen & Flurdenkmäler (Landkreis Lichtenfels)" }, place_of_worship),
    Object.assign({ name: 'bench-landkreis', title: "Bänke & Tische (Landkreis Bamberg)" }, bench),
    Object.assign({ name: 'bench-stadt', title: "Bänke & Tische (Stadt Bamberg)" }, bench),
    Object.assign({ name: 'waste-stadt', title: "Abfallwirtschaft (Stadt Bamberg)" }, waste),
    Object.assign({ name: 'toilets-stadt', title: "Toiletten (Stadt Bamberg)" }, toilets),
    Object.assign({ name: 'water-stadt', title: "Trinkwasser (Stadt Bamberg)" }, water),
    Object.assign({ name: 'post-stadt', title: "Post (Stadt Bamberg)" }, post),
    Object.assign({ name: 'healthcare-stadt', title: "Gesundheitssystem (Stadt Bamberg)" }, healthcare),
    Object.assign({ name: 'healthcare-landkreis', title: "Gesundheitssystem (Landkreis Bamberg)" }, healthcare),
    Object.assign({ name: 'fruits-stadt', title: "Obstbäume und -sträucher (Stadt Bamberg)" }, fruits),
    Object.assign({ name: 'fruits-landkreis', title: "Obstbäume und -sträucher (Landkreis Bamberg)" }, fruits),
    Object.assign({ name: 'surveillance-stadt', title: "Überwachung (Stadt Bamberg)" }, surveillance),
];
const DATASETS_BY_HOST = {
    'smartcity.heriberga.net': [
        'bench-stadt', 'waste-stadt', 'toilets-stadt',
        'water-stadt', 'post-stadt', 'healthcare-stadt',
        'fruits-stadt',
    ],
};
function filterDataSets(dataSets) {
    const names = DATASETS_BY_HOST[location.hostname];
    if (names)
        return dataSets.filter(dataSet => names.includes(dataSet.name));
    return dataSets;
}
const availableDataSets = filterDataSets(ALL_DATASETS);
let currentVariant;
let pois;
function variantChanged(variant) {
    return __awaiter(this, void 0, void 0, function* () {
        pois = undefined;
        if (!variant)
            return;
        yield tick();
        hash.dataset = variant.name;
        pois = yield load(`/data/${variant.name}.osm`, variant.match);
        hideDetails();
        if (!map.hasHashLocation())
            map.zoomTo(pois.bbox());
    });
}
$: variantChanged(currentVariant);
let currentDetails;
function showDetails(poi, context) {
    const shape = context.detail;
    currentDetails = { poi, shape };
}
function hideDetails() {
    currentDetails = undefined;
}
function hashChanged(hash) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!hash)
            return;
        yield tick();
        const name = hash.dataset;
        if (name) {
            if (!currentVariant || currentVariant.name != name) {
                const variant = availableDataSets.find(dataset => dataset.name == name);
                if (variant)
                    currentVariant = variant;
            }
        }
        if (!currentVariant)
            currentVariant = availableDataSets[0];
    });
}
let map;
let hash;
$: hashChanged(hash);
</script>

<Map bind:this={map} bind:hash={hash}>
{#if currentVariant && pois}
    {#each pois.nodes as node (node)}
        <Shape
            point={node.point}
            style={currentVariant.styleFor(node)}
            on:click={(e) => showDetails(node, e)}
        />
    {/each}
    {#each pois.ways as way (way)}
        {#if way.area}
            <Shape
                area={way.points}
                style={currentVariant.styleFor(way)}
                on:click={(e) => showDetails(way, e)}
            />
        {:else}
            <Shape
                line={way.points}
                style={currentVariant.styleFor(way)}
                on:click={(e) => showDetails(way, e)}
            />
        {/if}
    {/each}
    <Popup shape={currentDetails?.shape} on:remove={hideDetails}>
        {#if currentDetails}
            <Details
                poi={currentDetails.poi}
                linksOf={currentVariant.linksOf}
                imagesOf={currentVariant.imagesOf}
                tagsOf={currentVariant.tagsOf}
                membershipsOf={currentVariant.membershipsOf}
            />
        {/if}
    </Popup>
{/if}
</Map>

<aside>
    <select bind:value={currentVariant}>
    {#if !currentVariant}
        <option value={undefined}></option>
    {/if}
    {#each availableDataSets as dataset (dataset.name)}
        <option value={dataset}>{dataset.title}</option>
    {/each}
    </select>
{#if pois}
    <small>{pois.date.toLocaleString()}</small>
{/if}
</aside>

<style lang="scss">aside {
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1000;
}
aside small {
  display: block;
}</style>
