import type {Tags} from '../lib/osm';
import type {POI, Link, POIWithKind} from '../poi';
import {linksOfCommon, imagesOfCommon, includesTag, hasTag, pickTags, pickContinuedTags} from '.';

enum Kind {
    BENCH,
    PICNIC_TABLE,
    SHELTER_WITH_BENCH,
    SHELTER_WITHOUT_BENCH,
}

const STYLES: {[key in Kind]: {[key: string]: string}} = {
    [Kind.BENCH]: {color: '#00c000'},
    [Kind.PICNIC_TABLE]: {color: '#0000ff'},
    [Kind.SHELTER_WITH_BENCH]: {color: '#ff0000'},
    [Kind.SHELTER_WITHOUT_BENCH]: {color: '#ff8000'},
};

function styleFor(poi: POIWithKind): {[key: string]: string} {
    let style = STYLES[poi.kind as Kind];
    if(poi.tags.access && poi.tags.access != 'yes')
        style = {...style, color: `${style.color}40`};
    return style;
}

function match(tags: Tags): Kind | undefined {
    if(includesTag(tags.amenity, 'hunting_stand'))
        return;
    if(includesTag(tags.amenity, 'shelter') || hasTag(tags.shelter)) {
        if(hasTag(tags.bench))
            return Kind.SHELTER_WITH_BENCH;
        else
            return Kind.SHELTER_WITHOUT_BENCH;
    }
    if(includesTag(tags.leisure, 'picnic_table') ||
       includesTag(tags.tourism, 'picnic_site') ||
       includesTag(tags.amenity, 'table'))
        return Kind.PICNIC_TABLE;
    if(includesTag(tags.amenity, 'bench') || hasTag(tags.bench))
        return Kind.BENCH;
    if(includesTag(tags.amenity, 'lounger'))
        return Kind.BENCH;
}

function linksOf(poi: POI): Array<Link> {
    const tags = poi.tags;
    const links: Array<Link> = [
        ...linksOfCommon(poi),
    ];
    if(tags['openbenches:id'])
        links.push({
            title: "OpenBenches",
            href: `https://openbenches.org/bench/${tags['openbenches:id']}`,
        })
    return links;
}

function tagsOf(poi: POI): Tags {
    const tags = poi.tags;
    const result: Tags = {};

    pickTags(result, poi, 'amenity', 'leisure', 'tourism');
    if(includesTag(tags.highway, 'bus_stop'))
        result.highway = tags.highway;
    pickTags(result, poi, 'shelter_type', 'description', 'operator');
    pickContinuedTags(result, poi, 'inscription');
    if(hasTag(tags.informal))
        result.informal = tags.informal;
    pickTags(result, poi, 'seats', 'material', 'surface', 'color', 'backrest', 'covered',
        'shelter', 'bench', 'bin', 'fireplace', 'drinking_water', 'seasonal', 'opening_hours',
        'availability', 'start_date');
    if(tags.access && tags.access != 'yes')
        result.access = tags.access;
    pickTags(result, poi, 'note', 'fixme');
    return result;
}

export {Kind, styleFor, match, linksOf, imagesOfCommon as imagesOf, tagsOf};
