<script lang="ts">import { getContext, setContext, createEventDispatcher } from 'svelte';
import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '../lib/query-string';
import { encode as encodeQueryString, decode as decodeQueryString } from '../lib/query-string';
let map;
const dispatch = createEventDispatcher();
setContext('map', () => map);
export function zoomTo(bbox) {
    if (bbox.isValid())
        map.fitBounds(bbox);
}
function setView(location) {
    map.setView({ lat: location.lat, lng: location.lng }, location.zoom);
}
export let location = undefined;
let locationWas;
$: applyLocation(location);
export let hash = {};
$: persistHash(hash);
function applyLocation(location) {
    if (!location && !locationWas)
        return;
    if (location && locationWas &&
        location.zoom == locationWas.zoom &&
        location.lat == locationWas.lat &&
        location.lng == locationWas.lng)
        return;
    if (location) {
        setView(location);
        persistHash(hash);
    }
    locationWas = location;
}
function persistHash(hash) {
    if (!map)
        return;
    if (location) {
        const { zoom, lat, lng } = location;
        const precision = Math.max(0, Math.ceil(Math.log(zoom) / Math.LN2));
        hash = Object.assign(Object.assign({}, hash), { map: `${zoom}/${lat.toFixed(precision)}/${lng.toFixed(precision)}` });
    }
    let qs = encodeQueryString(hash);
    if (qs)
        qs = '#' + qs;
    if (qs != global.location.hash)
        global.location.hash = qs;
}
function onHashChange() {
    const values = decodeQueryString(global.location.hash);
    if (values.map) {
        const parts = values.map.split('/');
        const zoom = parseInt(parts[0]), lat = parseFloat(parts[1]), lng = parseFloat(parts[2]);
        if (!isNaN(zoom) && !isNaN(lat) && !isNaN(lng))
            location = { zoom, lat, lng };
    }
    else {
        location = undefined;
    }
    delete values.map;
    hash = values;
}
function onMoveEnd() {
    const center = map.getCenter(), zoom = map.getZoom();
    location = Object.assign(Object.assign({}, center), { zoom });
}
export function hasHashLocation() {
    onHashChange();
    return !!location;
}
function createLeaflet(node) {
    map = L.map(node, {
        preferCanvas: true,
    });
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&#127279; <a href="https://www.openstreetmap.org/">OpenStreetMap</a>',
        maxZoom: 19,
    }).addTo(map);
    map.addEventListener('moveend', onMoveEnd);
    window.addEventListener('hashchange', onHashChange);
    onHashChange();
    dispatch('load');
    return {
        destroy() {
            window.removeEventListener('hashchange', onHashChange);
            map.remove();
            map = undefined;
        },
    };
}
</script>

<script lang="ts" context="module">export function get() {
    return getContext('map')();
}
</script>

<div use:createLeaflet>
    {#if map}
        <slot />
    {/if}
</div>

<style>
    div {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }
</style>
