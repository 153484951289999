import type {Tags} from '../lib/osm';
import type {POI, POIWithKind} from '../poi';
import {linksOfCommon, imagesOfCommon, includesTag, pickTags, hasTag} from '.';

enum Kind {
    CAMERA,
    OTHER,
}

const STYLES: {[key in Kind]: {[key: string]: string}} = {
    [Kind.CAMERA]: {color: '#ff0000'},
    [Kind.OTHER]: {color: '#ffff00'},
};

function styleFor(poi: POIWithKind): {[key: string]: string} {
    let style = STYLES[poi.kind as Kind];
    if(poi.tags.access && poi.tags.access != 'yes')
        style = {...style, color: `${style.color}40`};
    return style;
}

function match(tags: Tags): Kind | undefined {
    if(includesTag(tags['surveillance:type'], 'camera')  ||
       hasTag(tags['camera:type']) || hasTag(tags['camera:mount']) || hasTag(tags['camera:direction']))
        return Kind.CAMERA;
    if(includesTag(tags.man_made, 'surveillance') || hasTag(tags.surveillance))
        return Kind.OTHER;
}

function tagsOf(poi: POI): Tags {
    const tags = poi.tags;
    const result: Tags = {};

    pickTags(result, poi, 'man_made');
    pickTags(result, poi, 'ref', 'description', 'operator');
    pickTags(result, poi, 'surveillance', 'surveillance:', 'camera', 'camera:');
    pickTags(result, poi, 'height');
    if(tags.access && tags.access != 'yes')
        result.access = tags.access;
    pickTags(result, poi, 'note', 'fixme');
    return result;
}

export {Kind, styleFor, match, linksOfCommon as linksOf, imagesOfCommon as imagesOf, tagsOf};
