<script lang="ts">
    import {onDestroy, createEventDispatcher} from 'svelte';
    import * as L from 'leaflet';
    import 'Leaflet.Deflate';
    import type {LineString} from 'geojson';
    import {get as getMap} from './Map.svelte';

    export let point: L.LatLngExpression | undefined = undefined;
    export let line: L.LatLngExpression[] | L.LatLngExpression[][] | undefined = undefined;
    export let area: L.LatLngExpression[] | L.LatLngExpression[][] | L.LatLngExpression[][][] | undefined = undefined;
    export let style: object | undefined = undefined;

    const MARKER_RADIUS = matchMedia('(pointer: fine)') ? 6 : 10;

    let shape: LeafletShape;

    if(+!!point + +!!line + +!!area != 1) {
        throw "need exactly one of `point`, `line`, or `area`";
    }

    if(point)
        shape = new L.CircleMarker(point, {radius: MARKER_RADIUS, ...style});
    if(line)
        shape = new L.Polyline<LineString>(line, style);
    if(area)
        shape = new L.Polygon(area, style);

    if(!(shape!))
        shape = undefined as never;

    const dispatch = createEventDispatcher();
    shape.on('click', () => dispatch('click', shape));

    const map = getMap();

    let deflate: L.LayerGroup | undefined;
    if(line || area) {
        deflate = L.deflate({
            minSize: 2 * MARKER_RADIUS,
            markerType: L.circleMarker,
            markerOptions: {
                radius: MARKER_RADIUS,
                ...style,
            }
        });
        deflate.addTo(map);
    }

    shape.addTo(deflate ?? map);

    onDestroy(() => {
        if(shape)
            shape.remove();
        if(deflate)
            deflate.remove();
    });
</script>

<script lang="ts" context="module">
    type LeafletShape = L.Polygon | L.Polyline<LineString> | L.CircleMarker;

    export type {LeafletShape};
</script>
