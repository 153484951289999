import type {Tags} from '../lib/osm';
import type {POI, Link, POIWithKind} from '../poi';
import {linksOfCommon, imagesOfCommon, includesTag, pickTags, pickContinuedTags} from '.';

enum Kind {
    PLACE_OF_WORSHIP,
    MONASTERY,
    WAYSIDE_CROSS,
    MEMORIAL,
    ARTWORK,
}

const STYLES: {[key in Kind]: {[key: string]: string}} = {
    [Kind.PLACE_OF_WORSHIP]: {color: 'blue'},
    [Kind.MONASTERY]: {color: 'orange'},
    [Kind.WAYSIDE_CROSS]: {color: 'red'},
    [Kind.MEMORIAL]: {color: '#00c000'},
    [Kind.ARTWORK]: {color: '#ff80ff'},
};

function styleFor(poi: POIWithKind): {[key: string]: string} {
    return STYLES[poi.kind as Kind];
}

function match(tags: Tags): Kind | undefined {
    if(includesTag(tags.historic, 'wayside_cross', 'wayside_shrine', 'wayside_chapel', 'tree_shrine'))
        return Kind.WAYSIDE_CROSS;
    if(includesTag(tags.man_made, 'cross'))
        return Kind.WAYSIDE_CROSS;
    if(includesTag(tags.stone_type, 'conciliation_cross'))
        return Kind.WAYSIDE_CROSS;
    if(includesTag(tags.artwork_subject, 'religious'))
        return Kind.ARTWORK;
    if(includesTag(tags.amenity, 'monastery') ||
       includesTag(tags.building, 'monastery') ||
       includesTag(tags.history, 'monastery'))
        return Kind.MONASTERY;
    if(includesTag(tags.amenity, 'place_of_worship'))
        return Kind.PLACE_OF_WORSHIP;
    if(includesTag(tags.building,
        'cathedral', 'church', 'chapel', 'shrine',
        'temple', 'synagogue', 'mosque', 'religious',
        'wayside_shrine', 'wayside_chapel'))
        return Kind.PLACE_OF_WORSHIP;
    if(includesTag(tags.historic, 'church', 'chapel'))
        return Kind.PLACE_OF_WORSHIP;
    if(includesTag(tags.historic, 'memorial') && tags.memorial != 'plaque' &&
       tags['memorial:type'] != 'stolperstein' && tags.memorial != 'stolperstein')
        return Kind.MEMORIAL;
    if(includesTag(tags.historic, 'stone', 'boundary_stone', 'milestone'))
        return Kind.MEMORIAL;
    if(includesTag(tags.historic, 'coat_of_arms'))
        return Kind.MEMORIAL;
}

function linksOf(poi: POI): Array<Link> {
    const tags = poi.tags;
    const links: Array<Link> = [
        ...linksOfCommon(poi),
    ];
    if(tags['ref:BLfD'])
        links.push({
            title: "BLfD",
            href: async () => {
                const response = await fetch(`https://geoportal.bayern.de/denkmalatlas/denkmalservice/v1/denkmal/preview/byaktennummer/${encodeURIComponent(tags['ref:BLfD'])}`);
                const results = await response.json();
                const result = results[0];
                return `https://geoportal.bayern.de/denkmalatlas/searchResult.html?koid=${encodeURIComponent(result.koid)}&objtyp=bau&top=1`;
            },
        });
    if(tags['openplaques:id'])
        links.push({
            title: "OpenPlaques",
            href: `https://openplaques.org/plaques/${tags['openplaques:id']}`,
        });
    return links;
}

function tagsOf(poi: POI): Tags {
    const tags = poi.tags;
    const result: Tags = {};
    pickTags(result, poi, 'route', 'place_of_worship', 'place_of_worship:', 'worship');
    if(tags.building && tags.building != 'yes')
        result.building = tags.building;
    pickTags(result, poi, 'man_made');
    if(tags['summit:cross'] && tags['summit:cross'] != 'no')
        result['summit:cross'] = tags['summit:cross'];
    if(tags.historic && tags.historic != 'yes')
        result.historic = tags.historic;
    pickTags(result, poi, 'natural', 'stone_type', 'memorial', 'memorial:');
    if(tags.tourism == 'artwork')
        result.tourism = tags.tourism;
    pickTags(result, poi, 'artwork_type', 'artwork_subject', 'artist_name',
        'religion', 'denomination', 'basilica', 'church:', 'shrine',
        'religious_title', 'by:religious_title', 'date:religious_title',
        'diocese', 'deanery', 'parish', 'patron_saint', 'saint:name',
        'monastery:type', 'community', 'community:gender',
        'opening_hours', 'service_times', 'mass_times', 'operator',
        'description', 'material', 'start_date', 'indoor');
    pickContinuedTags(result, poi, 'inscription');
    if(tags.ruins && tags.ruins != 'no')
        result.ruins = tags.ruins;
    pickTags(result, poi, 'note', 'fixme');
    return result;
}

function membershipsOf(poi: POI) {
    if(!poi.relations) return [];
    return poi.relations.filter(membership => {
        const type = membership.relation.tags.type;
        return type == 'site' || type == 'collection' || type == 'route';
    });
}

export {Kind, styleFor, match, linksOf, imagesOfCommon as imagesOf, tagsOf, membershipsOf};
