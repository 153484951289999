<script lang="ts">
    import {createEventDispatcher, onDestroy} from 'svelte';
    import * as L from 'leaflet';
    import {get as getMap} from './Map.svelte';
    import type {LeafletShape} from './Shape.svelte';

    export let shape: LeafletShape | undefined;

    const dispatch = createEventDispatcher();

    const map = getMap();
    const popup = L.popup();

    const onPopupClose = () => {
        dispatch('popupclose', popup);
    };

    map.on('popupclose', onPopupClose);

    function showPopup(shape: LeafletShape) {
        if('marker' in shape)
            shape = (shape as any).marker;
        const latlng =
            'getLatLng' in shape ? shape.getLatLng() :
            'getCenter' in shape ? shape.getCenter() :
            undefined as never;
        popup
            .setLatLng(latlng)
            .setContent(content)
            .openOn(map);
        setTimeout(() => popup.update(), 0);
    }

    function hidePopup() {
        popup.remove();
    }

    let content: HTMLElement;

    $: {
        if(shape && $$slots.default)
            showPopup(shape);
        else
            hidePopup();
    }

    onDestroy(() => {
        dispatch('remove', popup);
        map.off('popupclose', onPopupClose);
        popup.remove();
    });
</script>

<div bind:this={content}>
    <slot />
</div>
